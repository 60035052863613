<template>
  <div class="ob-ed ob-news__letter">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img
                src="@/assets/images/onboarding/email-learning.svg"
                alt="newsletter"
              />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.news_letter.title") }}
            </h2>
            <div class="ob-main__sec">
              <b-form>
                <b-row class="mb-4">
                  <b-col
                    cols
                    sm="12"
                    class="ob-responsive__view ob-responsive__pills"
                  >
                    <h3 class="ob-sub__heading mb-3">
                      {{ $t("onboarding.news_letter.subtitle") }}
                    </h3>
                    <b-media
                      v-for="(item, index) in mediaItems"
                      :key="index"
                      class="mb-2"
                    >
                      <template #aside>
                        <b-img
                          :src="
                            require(`@/assets/images/onboarding/${item.image}`)
                          "
                          width="24"
                          alt="placeholder"
                        ></b-img>
                      </template>
                      <b-card-text class="px-2 ob-news__desc">
                        <b>{{ item.title }}:</b> {{ item.description }}
                      </b-card-text>
                    </b-media>
                  </b-col>
                </b-row>
                <div class="ob-ed__cta">
                  <b-row>
                    <b-col>
                      <b-button
                        variant="primary"
                        class="gradient-bg btn-subscribe mr-4"
                        @click="onNext($event, true)"
                      >
                        {{
                          $t("onboarding.news_letter.button_subscribe")
                        }}</b-button
                      >
                      <b-button
                        class="btn-dismiss mr-3"
                        @click="onNext($event, false)"
                        >{{
                          $t("onboarding.news_letter.button_dismiss")
                        }}</b-button
                      >
                      <span class="ob-ed__context_left d-sm-none d-md-inline">
                        {{ $t("onboarding.enter") }}
                      </span>
                      <span class="ob-ed__context_icon d-sm-none d-md-inline">
                        <img
                          src="@/assets/images/onboarding/show.svg"
                          alt="enter"
                          width="auto"
                          height="auto"
                        />
                      </span>
                      <span class="ob-ed__context_right">
                        {{ $t("onboarding.continue") }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import OnboardingLayout from "../Layout/OnboardingLayout";
import SettingsMixin from "@/components/Settings/mixin";
import OnboardingMixin from "@/components/Onboarding/mixin";

export default {
  mixins: [SettingsMixin, OnboardingMixin],
  components: { OnboardingLayout },
  data() {
    return {
      isCompleted: false,
      oForm: {
        is_news_letter: ""
      },
      currentStep: 0,
      showFreeText: false,
      mediaItems: [
        {
          image: "newsletter-course.svg",
          title: `${this.$t(
            "onboarding.news_letter.list_items.personalized_learning"
          )}`,
          description: `${this.$t(
            "onboarding.news_letter.list_items.personalized_description"
          )}`
        },
        {
          image: "newsletter-progress.svg",
          title: `${this.$t(
            "onboarding.news_letter.list_items.continuous_growth"
          )}`,
          description: `${this.$t(
            "onboarding.news_letter.list_items.continuous_description"
          )}`
        },
        {
          image: "newsletter-interests.svg",
          title: `${this.$t(
            "onboarding.news_letter.list_items.lifetime_learning"
          )}`,
          description: `${this.$t(
            "onboarding.news_letter.list_items.lifetime_description"
          )}`
        }
      ]
    };
  },
  mounted() {
    this.currentStep = this.step;
  },
  methods: {
    onNext(event, isSubscribed) {
      event.preventDefault();
      let obj = {
        is_news_letter: isSubscribed
      };
      this.updateOnboarding(obj, this.oForm).then(() => {
        this.$store.dispatch("getOnboardingPendingStatus").then(() => {
          this.$store.commit("SET_WELCOME_MODAL_STATUS", true);
          this.$router.push({ name: "Dashboard" });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
.ob-news__letter {
  .ob-ed__new {
    .ob-sub__heading {
      @include subtitle-regular;
      color: $brand-neutral-1000;
    }
    .media {
      width: 100%;
      max-width: 648px;
      .ob-news__desc {
        @include body-regular;
      }
    }
    .btn-subscribe,
    .btn-dismiss {
      @include subtitle-regular;
      line-height: 21px;
      letter-spacing: 1.25px;
      border-radius: 100px;
      padding: 16px 32px;
      border: 0px;
      cursor: pointer;
      transition: none;
      &:focus {
        outline: none;
      }
    }
    .btn-subscribe:hover {
      background: $brand-primary-600;
    }
    .btn-dismiss:hover {
      color: $brand-primary;
      border-color: $brand-primary;
    }
    .btn-dismiss {
      background: $brand-neutral-75;
      color: $brand-neutral-700;
      border: 1px solid $brand-neutral-75;
    }
    .ob-ed__context_left,
    .ob-ed__context_right {
      @include body-medium;
      color: $brand-neutral-300;
    }
  }
}
@media only screen and (max-width: 767px) {
  .ob-news__letter {
    .ob-ed__new {
      .ob-ed__cta {
        padding: 110px 16px 33px 16px;
        .btn-subscribe,
        .btn-dismiss {
          width: calc(100vw - 25px);
        }
        .btn-dismiss {
          background: none;
          border: none;
          color: $brand-primary;
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}
</style>
